import { Box, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { Tab, TabList } from '@reach/tabs';

export const VerticalTabList = styled(TabList)`
  display: flex;
  flex-direction: column;
`;

const sharedBeforeProps = `
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  background-color: ${theme.colors['white-200']};
  transition: height 250ms ease-out, opacity 250ms linear;
`;

/*
  Wrapping this because 'Tab' is not a gamut/emotion component and cannot receive any props directly.
  The styling is passed on from the wrapper to the child.
  Duplicating before element styles across active/inacative was necessary for transitions to work properly.
 */
export const ExpandableTab = styled(Tab)``;
export const TabWrapper = styled(Box)<{ isActiveTab: boolean }>`
  ${ExpandableTab} {
    color: ${theme.colors['text-secondary']};
    background: none;
    position: relative;
    padding: 16px;
    margin-bottom: 16px;
    border: none;
    border-radius: 8px;
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &:focus-visible {
      box-shadow: 0 0 0 2px ${theme.colors.yellow};
    }
    &:focus {
      outline: none;
    }
    ${(props) =>
      props.isActiveTab
        ? `
        color: white;
        padding: 16px 16px 24px;
        pointer-events: none;
        cursor: default;
        height: 100%;
        opacity: 1;
        &::before {
          ${sharedBeforeProps}
          height: 100%;
          opacity: 1;
          margin: -16px -16px -24px;
        }`
        : `
        &:hover {
          color: ${theme.colors['text-secondary']};
          background-color: ${theme.colors['white-100']};
          cursor: pointer;
        }
        &::before {
          ${sharedBeforeProps}
          height: 0%;
          opacity: 1;
        }
      `}
  }
`;

export const AnimatedTabHeader = styled(Text)<{ isWithinActiveTab: boolean }>`
  margin-bottom: ${(props) => (props.isWithinActiveTab ? '16px' : '0')};
  transition: transform 0.5s ease-out;
  transform: ${(props) => props.isWithinActiveTab && 'translate3d(0,8px,0)'};
`;

export const AnimatedTabDescription = styled(Text)<{
  isWithinActiveTab: boolean;
}>`
  overflow: hidden;
  height: ${(props) => (props.isWithinActiveTab ? 'auto' : 0)};
  opacity: ${(props) => (props.isWithinActiveTab ? 1 : 0)};
  transition: opacity 0.75s linear;
`;
