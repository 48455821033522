import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

export const gridLayouts = {
  vertical: `'cta cta cta'
             'editor editor editor'`,
  horizontalMd: `'editor cta cta'
               'editor cta cta'`,
  horizontalLg: `'editor none cta'
               'editor none cta'`,
};

export const CodeEditorGrid = styled.div`
  padding: 4rem 0;
  display: grid;
  grid-template-areas: ${gridLayouts.vertical};
  grid-template-columns: 58% 9% 35%;
  ${theme.breakpoints.md} {
    grid-template-areas: ${gridLayouts.horizontalMd};
  }
  ${theme.breakpoints.lg} {
    grid-template-areas: ${gridLayouts.horizontalLg};
  }
`;
