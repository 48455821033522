import {
  Column,
  ContentContainer,
  FillButton,
  FlexBox,
  LayoutGrid,
  Text,
} from '@codecademy/gamut';
import { Background, breakpoints, Colors } from '@codecademy/gamut-styles';
import React from 'react';

import { StyledImage } from './styles';

interface CtaBlockCta {
  copy: string;
  href: string;
}

interface CtaBlockImage {
  desktop: {
    src: string;
    alt: string;
  };
  mobile: {
    src: string;
    alt: string;
  };
}
export interface CtaBlockData {
  title: string;
  description: string;
  cta: CtaBlockCta;
  image: CtaBlockImage;
}
interface CTABlockProps {
  backgroundColor: Colors;
  ctaPosition?: 'right' | 'left';
  onCtaClick: () => void;
  ctaBlockData: CtaBlockData;
}

export const CTABlock: React.FC<React.PropsWithChildren<CTABlockProps>> = ({
  backgroundColor,
  ctaPosition = 'left',
  onCtaClick,
  ctaBlockData,
}) => {
  const textSection = (
    <FlexBox
      column
      alignItems="flex-start"
      justifyContent="center"
      mb={{
        _: 16,
        xs: 0,
      }}
      maxWidth={315}
    >
      <Text
        fontWeight={700}
        lineHeight="title"
        my={8}
        fontSize={{ _: 34, xs: 44 }}
        as="h2"
      >
        {ctaBlockData.title}
      </Text>
      <Text>{ctaBlockData.description}</Text>
      <FillButton
        mt={24}
        data-testid="cta-block-button"
        href={ctaBlockData.cta.href}
        onClick={onCtaClick}
      >
        {ctaBlockData.cta.copy}
      </FillButton>
    </FlexBox>
  );

  const imageSection = (
    <picture>
      <source
        media={`(min-width: ${breakpoints.sm})`}
        srcSet={ctaBlockData.image.desktop.src}
      />
      <source
        media={`(max-width: ${parseInt(breakpoints.sm, 10) - 1}px)`}
        srcSet={ctaBlockData.image.mobile.src}
      />
      <StyledImage
        src={ctaBlockData.image.desktop.src}
        alt={ctaBlockData.image.desktop.alt}
      />
    </picture>
  );

  const ctaRight = ctaPosition === 'right';

  return (
    <Background bg={backgroundColor}>
      <ContentContainer>
        <LayoutGrid py={{ _: 48, md: 96 }}>
          <Column
            offset={{ _: 0, xs: 1 }}
            size={{ _: 12, xs: 4 }}
            gridRow={{
              _: ctaRight ? 1 : 2,
              xs: 1,
            }}
          >
            {ctaRight ? (
              <FlexBox justifyContent="center" alignItems="center">
                {imageSection}
              </FlexBox>
            ) : (
              textSection
            )}
          </Column>
          <Column
            offset={{ _: 0, xs: 7 }}
            size={{ _: 12, xs: 5, sm: 4 }}
            gridRow={{
              _: ctaRight ? 2 : 1,
              xs: 1,
            }}
          >
            <FlexBox
              justifyContent={{
                _: ctaRight ? 'flex-start' : 'center',
                xs: 'center',
              }}
              alignItems="center"
            >
              {ctaRight ? textSection : imageSection}
            </FlexBox>
          </Column>
        </LayoutGrid>
      </ContentContainer>
    </Background>
  );
};
