import {
  Box,
  Column,
  ContentContainer,
  GridBox,
  LayoutGrid,
} from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React from 'react';

import AirbnbLogo from './assets/AirbnbLogo.svg';
import AmazonLogo from './assets/AmazonLogo.svg';
import DailymotionLogo from './assets/DailymotionLogo.svg';
import ReverbLogo from './assets/ReverbLogo.svg';
import { BusinessCTA } from './BusinessCTA';

const teamsLogos = [
  {
    src: AirbnbLogo,
    alt: 'Airbnb',
    height: '37px',
    offset: 0,
  },
  {
    src: AmazonLogo,
    alt: 'Amazon',
    height: '28px',
    offset: 7,
  },
  {
    src: DailymotionLogo,
    alt: 'Dailymotion',
    height: '28px',
    offset: 0,
  },
  {
    src: ReverbLogo,
    alt: 'Reverb',
    height: '28px',
    offset: -5,
  },
];

const LogoImage = styled.img<{ height: string }>`
  height: ${(props) => props.height || '28px'};

  @media (min-width: 1024px) {
    height: auto;
  }
`;

export const BusinessBanner: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Background bg="navy">
    <ContentContainer>
      <LayoutGrid>
        <Column offset={{ _: 0, md: 1 }} size={{ _: 12, md: 5 }}>
          <BusinessCTA />
        </Column>
        <Column
          offset={{ _: 0, md: 7 }}
          size={{ _: 12, md: 6 }}
          pb={{ _: 40, md: 0 }}
        >
          <GridBox
            as="ul"
            listStyleType="none"
            p={0}
            my="auto"
            gridTemplateColumns={{
              _: 'repeat(2, auto)',
              sm: 'repeat(4, auto)',
              md: 'repeat(2, auto)',
            }}
            alignItems="center"
            justifyContent={{
              _: 'stretch',
              sm: 'space-between',
              md: 'center',
            }}
            rowGap={{ _: 24, sm: 24, md: 48 }}
            columnGap={{ _: 32, sm: 24, md: 40 }}
          >
            {teamsLogos.map((logo) => (
              <Box as="li" position="relative" top={logo.offset} key={logo.alt}>
                <LogoImage
                  src={logo.src || ''}
                  alt={logo.alt || ''}
                  height={logo.height}
                  key={logo.alt}
                  width="auto"
                />
              </Box>
            ))}
          </GridBox>
        </Column>
      </LayoutGrid>
    </ContentContainer>
  </Background>
);
