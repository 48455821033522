import { Box, FlexBox, Text } from '@codecademy/gamut';
import { AddBoldIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';
import React from 'react';

import type { CarouselItem, InclusiveBreakpoint } from './consts';
import { transitionDuration } from './consts';

const inclusiveBreakpoints: InclusiveBreakpoint[] = [
  '_',
  'sm',
  'md',
  'lg',
  'xl',
];

const Copy = styled(FlexBox)<{ isHidden: boolean }>`
  transition: opacity ${transitionDuration} ease-out;
  ${({ isHidden }) => (isHidden ? 'pointer-events: none;' : '')}
`;

const BulletText = styled(Box)`
  text-rendering: geometricPrecision;
  line-height: 18px;
`;

export const CarouselCopy: React.FC<{
  carouselItems: CarouselItem[];
  currentIndex: number;
}> = ({ carouselItems, currentIndex }) => (
  <>
    {carouselItems.map((item, i) => {
      const isHidden = i !== currentIndex;

      return (
        <Copy
          key={item.title.copy}
          position="absolute"
          top={{ _: 32, xl: 48 }}
          bottom={64}
          left={{ _: 32, sm: 64, lg: 96 }}
          opacity={isHidden ? 0 : 1}
          isHidden={isHidden}
          aria-hidden={isHidden}
          flexDirection="column"
          justifyContent={{ _: 'space-between', sm: 'flex-start' }}
          aria-label={`slide ${i + 1} of ${carouselItems.length}`}
        >
          <Box maxWidth={{ _: 300, sm: 500, md: 300, lg: 450, xl: 500 }}>
            <Text
              as="h2"
              display="inline"
              variant="title-xxl"
              textTransform="uppercase"
              lineHeight={{ _: '1em' } as {}}
              px={4}
              pt={4}
              bg="beige"
              fontSize={
                item.title.fontSize ?? {
                  _: 44,
                  sm: 72 as 16,
                  md: 44,
                  lg: 64,
                  xl: 72 as 16,
                }
              }
            >
              {item.title.copy}
            </Text>
          </Box>
          {inclusiveBreakpoints.map((bp) => (
            <Box
              as="ul"
              listStyle="none"
              key={'list-' + bp}
              p={0}
              mt={8}
              display={{
                _: bp === '_' ? 'block' : 'none',
                sm: bp === 'sm' ? 'block' : 'none',
                md: bp === 'md' ? 'block' : 'none',
                lg: bp === 'lg' ? 'block' : 'none',
                xl: bp === 'xl' ? 'block' : 'none',
              }}
            >
              {item.bullets.copy[bp].map((bullet) => (
                <FlexBox
                  key={bullet}
                  as="li"
                  bg="black"
                  color="beige"
                  width="max-content"
                  mt={8}
                  pr={4}
                  alignItems="center"
                >
                  <AddBoldIcon size={12} mx={8} flexShrink={0} />
                  <BulletText
                    fontFamily="accent"
                    fontWeight={600 as 700}
                    fontSize={14}
                    whiteSpace="pre"
                    p={2 as 0}
                  >
                    {bullet}
                  </BulletText>
                </FlexBox>
              ))}
            </Box>
          ))}
        </Copy>
      );
    })}
  </>
);
