import { Badge, Box, Card, FlexBox, Text } from '@codecademy/gamut';
import { CheckerDense } from '@codecademy/gamut-patterns';
import { pxRem, system, theme, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { getProductDescription, PlansByType } from '../config';
import { PlanFeature } from '../PlanFeature';
import { ProductCTA } from '../ProductCTA';
import { CopyConfig, CtaConfig, Product, ProductDetails } from '../types';
import { planDetails } from './consts';
import { PricingAmount } from './PricingAmount';
import { RecommendedBadge } from './RecommendedBadge';

export const smSize = 728;
const mdSize = 304;

const StyledList = styled(FlexBox)`
  padding-left: 0;
  margin-bottom: 0;
`.withComponent('ul');

const StyledCard = styled(Card)(
  system.css({
    height: 1,
    borderWidth: 0,
    padding: 0,
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    zIndex: 1,
  })
);

const StyledCardWithBorder = styled(Card)(
  system.css({
    height: 1,
    borderWidth: 2,
    padding: 0,
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    zIndex: 1,
  })
);

const StyledListRow = styled('li')<{ showRecommendedBadge?: boolean }>`
  width: 100%;
  max-width: ${pxRem(smSize)};
  height: 100%;
  display: grid;
  ${({ showRecommendedBadge }) => `
    margin-top: ${showRecommendedBadge ? '1.5rem' : '0'};
  `}
  ${theme.breakpoints.md} {
    margin-top: 0;
    min-width: ${pxRem(mdSize)};
  }
`;

const cardHeaderVariants = variant({
  base: {
    backgroundColor: 'transparent',
  },
  variants: {
    [Product.Basic]: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors['navy-200'],
      color: 'navy-500',
    },
    [Product.Silver]: {
      backgroundColor: theme.colors.paleYellow,
    },
    [Product.Gold]: {
      backgroundColor: theme.colors.yellow,
    },
    [Product.Pro]: {
      backgroundColor: theme.colors.yellow,
    },
    [Product.Teams]: {},
  },
});

const CardHeader = styled(FlexBox)(cardHeaderVariants);

const renderIncludesLabel = (product: Product, plansByType: PlansByType) => {
  let includesLabel = '';

  if (product === Product.Basic) {
    includesLabel = 'Included in Basic';
  } else if (
    // only include the plus plan label if there is also a basic plan card visible
    plansByType[Product.Basic] &&
    (product === Product.Pro || product === Product.Silver)
  ) {
    includesLabel = 'Everything in Basic and more';
  } else if (
    // only include the pro plan label if there is also a plus plan card visible
    (plansByType[Product.Pro] || plansByType[Product.Silver]) &&
    product === Product.Gold
  ) {
    includesLabel = 'Everything in Plus and more';
  }

  return includesLabel ? (
    <Text mt={16} fontWeight={700} width="fit-content" m="auto">
      {includesLabel}
    </Text>
  ) : null;
};

export const PricingCard: React.FC<{
  product: Product;
  productDetails: ProductDetails;
  plansByType: PlansByType;
  isUserInIndia: boolean;
  isCurrentPlan?: boolean;
  ctaConfig?: CtaConfig[keyof CtaConfig];
  copyConfig?: CopyConfig[keyof CopyConfig];
}> = ({
  product,
  productDetails,
  plansByType,
  isUserInIndia,
  isCurrentPlan,
  ctaConfig,
  copyConfig,
}) => {
  const isGoldProduct = product === Product.Gold;
  const isSilverProduct = product === Product.Silver;
  const isLegacyProduct = product === Product.Pro;

  const planDetail = planDetails[product];

  const CardComponent = !isGoldProduct ? StyledCard : StyledCardWithBorder;
  const isMultiple = true;

  return (
    <StyledListRow showRecommendedBadge={product === Product.Gold}>
      <CardComponent position="relative" display="flex">
        {product === Product.Gold && <RecommendedBadge />}
        <CardHeader
          variant={product}
          py={8}
          px={24}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text as="h2" fontSize={{ _: 22, xl: 26 }}>
            {
              plansByType[product as keyof typeof plansByType].monthly
                .displayName
            }
          </Text>
          {isGoldProduct && (
            <Badge variant="tertiary" ml={12}>
              Build a career
            </Badge>
          )}
          {(isSilverProduct || isLegacyProduct) && (
            <Badge variant="tertiary" ml={12}>
              Learn a skill
            </Badge>
          )}
        </CardHeader>

        <Box px={24} pt={24}>
          <FlexBox center alignItems="flex-start">
            <PricingAmount
              monthlyPrice={
                plansByType[product as keyof typeof plansByType].monthly.price
              }
              price={productDetails.price}
              product={product}
              isUserInIndia={isUserInIndia}
            />
          </FlexBox>

          <FlexBox column alignItems="center">
            <CheckerDense mt={16} height="0.1rem" />
            <FlexBox
              flexDirection="column"
              alignItems="center"
              my={12}
              width={{ _: 'unset', md: pxRem(256) }}
            >
              <Text maxWidth={{ md: '235px' }} textAlign="center">
                {copyConfig?.description || getProductDescription(product)}
              </Text>
              {renderIncludesLabel(product, plansByType)}
            </FlexBox>
          </FlexBox>
        </Box>
        <FlexBox
          justifyContent="flex-end"
          alignContent={isMultiple ? 'flex-start' : 'unset'}
          alignItems="center"
          position="relative"
          px={isMultiple ? 24 : { _: 40, sm: 0 }}
          py={24}
          m="auto"
          flexDirection="column"
          flexWrap="wrap"
          flexGrow={isMultiple ? 1 : { _: 1, sm: 'unset' }}
          width={isMultiple ? 'auto' : { _: '100%', sm: '65%' }}
        >
          <StyledList
            flexDirection={{ _: 'column' }}
            flexWrap={isMultiple ? 'unset' : { _: 'nowrap', xs: 'wrap' }}
            height={
              isMultiple
                ? 'unset'
                : {
                    _: 'unset',
                    xs: 188,
                  }
            }
            width={
              isMultiple
                ? 'unset'
                : {
                    _: 'unset',
                    xs: '100%',
                  }
            }
          >
            {planDetail.features.map((feature) => (
              <PlanFeature
                key={feature.label}
                feature={feature}
                isMultiple={isMultiple}
              />
            ))}
          </StyledList>
        </FlexBox>
        <FlexBox mb={24} mt="auto" column>
          <ProductCTA
            product={product}
            ctaConfig={ctaConfig}
            isCurrentPlan={isCurrentPlan}
          />
        </FlexBox>
      </CardComponent>
    </StyledListRow>
  );
};
