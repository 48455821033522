const features = [
  {
    label: 'Basic access to free courses',
    description:
      'Access basic material for courses in top subjects like HTML and JavaScript. Quizzes and projects not included with Basic.',
  },
  {
    label: 'Community support',
    description:
      'Connect with other learners, get answers to your questions, and attend fun events.',
  },
  {
    label: 'Learning resources',
    description:
      'Cheatsheets, docs, videos, and more to support your learning journey.',
  },
  {
    label: 'Real-world projects',
    description:
      'Access our full library of practice and portfolio projects. Apply your skills and build your portfolio.',
  },
  {
    label: 'All courses',
    description:
      'Unlimited access to 300+ stand-alone courses, including everything in Basic plus popular Pro courses like Learn Python 3.',
  },
  {
    label: 'Skill paths',
    description:
      'Curated learning paths teach you everything you need to gain a specific skill, like building a website or visualizing data.',
  },
  {
    label: 'Certificates of completion',
    description:
      'Earn a certificate for courses or paths you finish to share with your network as proof of completion.',
  },
  {
    label: 'Career paths',
    description:
      'Curated learning paths give you the knowledge and experience to land a job in tech like Data Scientist or Engineer.',
  },
  {
    label: 'Technical interview prep',
    description:
      'Technical interview prep courses, practice questions, code challenges, and more.',
  },
  {
    label: 'Code challenges',
    description:
      'Test your knowledge with coding problems based on real-world technical interviews.',
  },
  {
    label: 'Professional certifications',
    description:
      "Prove you're job-ready. Earn a professional certification when you pass all the exams in select career paths.",
  },
  {
    label: 'Career services',
    description:
      'Get personalized job listings and connect with employers looking for tech talent.',
  },
  {
    label: 'Assessments',
    description:
      'Test your skills as you build toward job-readiness. Certification exams are available in select career paths.',
  },
] as const;

const getFeatures = (
  // if not provided, all features are enabled
  enabledFeatures?: (typeof features)[number]['label'][]
) => {
  return features.map((feature) => ({
    ...feature,
    isEnabled: !enabledFeatures || enabledFeatures.includes(feature.label),
  }));
};

interface Detail {
  id: string;
  title: string;
  tag: string;
  isLite: boolean;
  features: {
    label: string;
    isEnabled: boolean;
    description?: string;
  }[];
}

type PlanDetails = Record<string, Detail>;

export const planDetails: PlanDetails = {
  pro: {
    id: 'pro',
    title: 'Pro',
    tag: 'Learn a skill',
    isLite: false,
    features: [
      {
        label: 'Real-world projects',
        isEnabled: true,
      },
      {
        label: 'All courses',
        isEnabled: true,
      },
      {
        label: 'Skill paths',
        isEnabled: true,
      },
    ],
  },
  'pro-gold': {
    id: 'pro-gold',
    title: 'Pro',
    tag: 'Build a career',
    isLite: false,
    features: getFeatures(),
  },

  'pro-silver': {
    id: 'pro-silver',
    title: 'Plus',
    tag: 'Learn a skill',
    isLite: true,
    features: getFeatures([
      'Basic access to free courses',
      'Community support',
      'Learning resources',
      'Real-world projects',
      'All courses',
      'Skill paths',
      'Certificates of completion',
    ]),
  },

  free: {
    id: 'free',
    title: 'Pro',
    tag: 'Build a career',
    isLite: false,
    features: getFeatures([
      'Basic access to free courses',
      'Community support',
      'Learning resources',
    ]),
  },
};
