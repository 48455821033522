import { Box } from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';

import { groupPlansByType } from './config';
import { Products } from './Products';
import type {
  CopyConfig,
  CtaConfig,
  PlanType,
  ProductDetails,
  User,
} from './types';

type PricingSectionProps = Omit<
  Partial<React.ComponentProps<typeof Background>>,
  'title'
> & {
  products: ProductDetails[];
  title?: React.ReactNode;
  planTypes?: PlanType[];
  ctaConfig?: CtaConfig;
  copyConfig?: CopyConfig;
  user?: User;
  isUserInIndia: boolean;
};

export const PricingSection: React.FC<PricingSectionProps> = ({
  products,
  title,
  planTypes,
  ctaConfig,
  copyConfig,
  user,
  isUserInIndia,
  ...bgProps
}) => {
  const filteredProducts = products.filter((product) => {
    // if planTypes prop is provided, only show products that match the specified planTypes
    const isValidPlanType = planTypes
      ? (planTypes as string[]).includes(product.planType)
      : product.planType !== 'teams';

    // if user prop is provided, only show upsell cards (and the user's current plan for comparison)
    if (user) {
      if (product.planType === 'basic') {
        return isValidPlanType && !user.proSilver && !user.proGold;
      }
      if (product.planType === 'pro-silver') {
        return isValidPlanType && !user.proGold;
      }
    }
    return isValidPlanType;
  });

  return (
    <Background
      bg="beige"
      px={{
        _: 16,
        md: 32,
        lg: 64,
        xl: 96,
      }}
      mb={48}
      {...bgProps}
    >
      <Box maxWidth={1440} px={{ _: 32, lg: 64, xl: 96 }} mx="auto">
        {title}
        <Products
          plansByType={groupPlansByType(filteredProducts)}
          ctaConfig={ctaConfig}
          copyConfig={copyConfig}
          user={user}
          isUserInIndia={isUserInIndia}
        />
      </Box>
    </Background>
  );
};
