import { Column, LayoutGrid } from '@codecademy/gamut';
import { DiagonalBDense } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { Goal } from '..';
import { CardLinks } from './CardLinks';
import { GoalTabs } from './GoalTabs';

const HyperPattern = styled(DiagonalBDense)`
  color: ${theme.colors.hyper};
`;

interface MobileGoalSectionProps {
  goals: Goal[];
  goalState: [number, React.Dispatch<React.SetStateAction<number>>];
}

/* This section is for both mobile and tablet */
export const MobileGoalSection: React.FC<
  React.PropsWithChildren<MobileGoalSectionProps>
> = ({ goals, goalState }) => {
  const [currentTabIndex, setCurrentTabIndex] = goalState;

  const goal = goals[currentTabIndex];

  return (
    <LayoutGrid rowGap={{ _: 32, sm: 8 }} py={32} columnGap={32}>
      <Column size={12}>
        <GoalTabs
          goals={goals}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
        >
          <CardLinks content={goal.relatedContent} />
        </GoalTabs>
      </Column>
      <HyperPattern
        width="100%"
        position="absolute"
        height="48px"
        bottom="0"
        left="0"
        data-testid="mobile-gs-hyperpattern"
        pointerEvents="none"
      />
    </LayoutGrid>
  );
};
