import { gql } from '@apollo/client';

export const getHomepageContentfulQuery = gql`
  fragment learnerStory on Section {
    type: __typename
    sys {
      id
    }
    heading
    description
    callToActionUrl
    image {
      url
    }
  }

  fragment image on ImageBlock {
    type: __typename
    sys {
      id
    }
    image {
      url
    }
    alt
  }

  fragment slideProps on SlideImageCaptions {
    type: __typename
    sys {
      id
    }
    titleFontSize
    slideImage {
      type: __typename
      sys {
        id
      }
      image {
        url
      }
    }
    captions {
      json
    }
  }

  fragment slide on CarouselSlide {
    type: __typename
    sys {
      id
    }
    title
    verticallyAlignImageWithTitle
    extraLargeBreakpoint {
      ...slideProps
    }
    largeBreakpoint {
      ...slideProps
    }
    mediumBreakpoint {
      ...slideProps
    }
    smallBreakpoint {
      ...slideProps
    }
    baseBreakpoint {
      ...slideProps
    }
  }

  fragment carouselSection on TitleFeaturesList {
    type: __typename
    sys {
      id
    }
    featuresCollection(limit: 5) {
      items {
        ...slide
      }
    }
  }

  fragment tabAndTabPanel on TitleDescription {
    type: __typename
    sys {
      id
    }
    title
    trackingTargetTitle
    description
    refsCollection(limit: 1) {
      items {
        ...image
      }
    }
  }

  fragment ctas on TextHref {
    type: __typename
    sys {
      id
    }
    text
    href
  }

  fragment platformShowcaseSection on TitleFeaturesList {
    type: __typename
    sys {
      id
    }
    title
    eyebrow
    featuresCollection(limit: 5) {
      items {
        ...tabAndTabPanel
      }
    }
    refsCollection(limit: 2) {
      items {
        ...ctas
      }
    }
  }

  fragment experimentalHeroTitle on TitleDescription {
    type: __typename
    sys {
      id
    }
    title
  }

  fragment ctaBlockSection on Section {
    type: __typename
    sys {
      id
    }
    heading
    description
    callToAction
    callToActionUrl
    imageBlock {
      ...image
    }
    imageBlocksCollection(limit: 1) {
      items {
        ...image
      }
    }
  }

  fragment homepageVariationContainer on VariationContainer {
    type: __typename
    sys {
      id
    }
    experimentTitle
    experimentId
    meta
    variationsCollection(limit: 3) {
      items {
        ...learnerStory
        ...experimentalHeroTitle
        ...carouselSection
        ...platformShowcaseSection
        ...ctaBlockSection
      }
    }
    experimentKey
  }

  fragment learnerStoriesSection on Section {
    type: __typename
    sys {
      id
    }
    heading
    description
    refsCollection(limit: 4) {
      items {
        ...learnerStory
        ...homepageVariationContainer
      }
    }
  }

  fragment curriculumContent on ContentReference {
    type: __typename
    sys {
      id
    }
    contentType: type
    slug
  }

  fragment goal on Goal {
    type: __typename
    sys {
      id
    }
    title
    trackingTitle
    description
    relatedContentCollection(limit: 4) {
      items {
        ...curriculumContent
      }
    }
  }

  query getHomepageContentful($locale: String, $preview: Boolean) {
    homepage(id: "4wyl1lRB9OqtvYjXFNU85Y", preview: $preview) {
      heroTitle(locale: $locale)
      experimentalHeroTitle(locale: $locale) {
        ... on VariationContainer {
          ...homepageVariationContainer
        }
        ... on TitleDescription {
          ...experimentalHeroTitle
        }
      }
      carousel(locale: $locale) {
        ... on TitleFeaturesList {
          ...carouselSection
        }
        ... on VariationContainer {
          ...homepageVariationContainer
        }
      }
      learnerStories {
        ... on Section {
          ...learnerStoriesSection
        }
      }
      goalsCollection(limit: 6) {
        items {
          ... on Goal {
            ...goal
          }
        }
      }
      platformShowcase {
        ... on VariationContainer {
          ...homepageVariationContainer
        }
        ... on TitleFeaturesList {
          ...platformShowcaseSection
        }
      }
      ctaBlock(locale: $locale) {
        ... on VariationContainer {
          ...homepageVariationContainer
        }
        ... on Section {
          ...ctaBlockSection
        }
      }
    }
  }
`;
