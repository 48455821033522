import { CtaBlockData } from '.';
import defaultLearnersDesktop from './assets/LearnersDesktop.png';
import defaultLearnersMobile from './assets/LearnersMobile.png';

export const defaultCtaBlockData: CtaBlockData = {
  title: 'Start for free',
  description:
    'If you’ve made it this far, you must be at least a little curious. Sign up and take the first step toward your goals.',
  cta: {
    copy: 'Sign up',
    href: '/register',
  },
  image: {
    desktop: {
      src: defaultLearnersDesktop,
      alt: 'Two learners on their laptops',
    },
    mobile: {
      src: defaultLearnersMobile,
      alt: 'A learner on their laptop',
    },
  },
};
