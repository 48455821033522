import { Box, FlexBox } from '@codecademy/gamut';
import React from 'react';

import { questionnaire } from '../consts';
import { QuestionToggle } from './QuestionToggle';

export const NumberedNavigator: React.FC = () => {
  return (
    <FlexBox
      alignItems="center"
      as="ul"
      flexDirection={{ _: 'row', sm: 'column' }}
      justifyContent={{ _: 'center', sm: 'initial' }}
      height="50%"
      width={{ _: '100%', sm: '50%' }}
      gap={16}
      maxHeight={200}
      mx="auto"
      px={0}
      style={{ listStyle: 'none' }}
    >
      {questionnaire.map(({ key }, index) => (
        <Box display={{ _: 'inline', sm: 'block' }} as="li" key={key}>
          <QuestionToggle questionKey={key} index={index} />
        </Box>
      ))}
    </FlexBox>
  );
};
