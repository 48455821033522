import { Box, FlexBox, InfoTip, Text } from '@codecademy/gamut';
import { CloseCircleIcon, MiniCheckCircleIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';

interface PlanFeatureProps {
  feature: {
    label: string;
    description?: string;
    isEnabled: boolean;
  };
  isMultiple: boolean;
}

const ListItem = styled(FlexBox)`
  list-style-type: none;
`.withComponent('li');

const HiddenBox = styled(Box)`
  visibility: hidden;
`;

export const PlanFeature: React.FC<PlanFeatureProps> = ({
  feature: { isEnabled, label, description },
  isMultiple,
}) => {
  const Icon = isEnabled ? MiniCheckCircleIcon : CloseCircleIcon;

  return (
    <ListItem
      alignItems={isMultiple ? 'center' : { _: 'center', xs: 'flex-start' }}
      mt={4}
      mr={isMultiple ? 'unset' : { _: 'unset', xs: 24, sm: 8 }}
      width={isMultiple ? 'unset' : { _: 'unset', xs: '50%' }}
    >
      <Icon
        mr={8}
        mt={isMultiple ? 0 : { xs: 4 }}
        flexShrink={0}
        color={isEnabled ? 'green' : 'gray-600'}
        aria-hidden={false}
        aria-label={isEnabled ? 'included: ' : 'not included: '}
      />

      <FlexBox alignItems="center">
        {isEnabled ? (
          <>
            <Text width="100%" mr={4}>
              {label}
            </Text>
            <InfoTip info={description} placement="floating" />
          </>
        ) : (
          <>
            <Text textDecoration="line-through" color="text-disabled">
              {label}
            </Text>

            {/* this looks odd but it ensures each list item is the same height, as items without an InfoTip would otherwise be shorter */}
            <HiddenBox>
              <InfoTip info="" />
            </HiddenBox>
          </>
        )}
      </FlexBox>
    </ListItem>
  );
};
