import { FlexBox, Text } from '@codecademy/gamut';
import { MiniStarIcon } from '@codecademy/gamut-icons';
import { ComponentProps } from 'react';

export const RecommendedBadge: React.FC<ComponentProps<typeof FlexBox>> = (
  props
) => (
  <FlexBox
    position="absolute"
    top={-28}
    left={-2}
    bg="navy"
    color="white"
    p={8}
    borderRadiusTop="sm"
    borderRadiusBottom="none"
    center
    {...props}
  >
    <MiniStarIcon mr={8} size={12} />
    <Text fontFamily="accent" lineHeight={0} fontSize={14}>
      Recommended
    </Text>
  </FlexBox>
);
