import { TextProps } from '@codecademy/gamut';
import { getAssetPath } from '@mono/util/url';

import { ImageSetProps } from './ImageSet';

type TitleProps = Pick<TextProps, 'lineHeight' | 'fontSize'> & {
  copy: string;
};

export const transitionDuration = '0.5s';

// Used instead of media size to enforce _ instead of xs
export type InclusiveBreakpoint = '_' | 'sm' | 'md' | 'lg' | 'xl';

const imgBase = getAssetPath('homepage/hero-carousel');

export interface CarouselItem {
  title: TitleProps;
  bullets: {
    // Specific copy is used for manual per-breakpoint linebreaking.
    // This is necessary:
    // 1. For copy that breaks in certain places to not obstruct image contents
    // 2. To acheive closer hug of background to text using whiteSpace="pre"
    copy: Record<InclusiveBreakpoint, string[]>;
  };
  img: ImageSetProps;
}

export const defaultCarouselItems: CarouselItem[] = [
  {
    title: {
      copy: 'THE NEXT GENERATION OF LEARNING',
    },
    bullets: {
      copy: {
        _: [
          'Build skills quicker with\nAI assistance',
          'Gain experience with\nmodern developer tools',
        ],
        sm: [
          'Build skills quicker with AI assistance',
          'Gain experience with modern developer tools',
        ],
        md: [
          'Build skills quicker with AI assistance',
          'Gain experience with modern developer tools',
        ],
        lg: [
          'Build skills quicker with AI assistance',
          'Gain experience with modern developer tools',
        ],
        xl: [
          'Build skills quicker with AI assistance',
          'Gain experience with modern developer tools',
        ],
      },
    },
    img: {
      sources: {
        lg: `${imgBase}/v2/AI-goals-L.svg`,
        md: `${imgBase}/v2/AI-goals-M.svg`,
        sm: `${imgBase}/v2/AI-goals-S.svg`,
        xl: `${imgBase}/v2/AI-goals-XL.svg`,
        xs: `${imgBase}/v2/AI-goals-XS.svg`,
      },
      verticallyAlign: true,
    },
  },
  {
    title: {
      copy: 'Build your tech career',
    },
    bullets: {
      copy: {
        _: [
          'Get personalized job\nlistings and practice\ninterview questions',
          'Assess your job-readiness\nwith AI',
        ],
        sm: [
          'Get personalized job listings and\npractice interview questions',
          'Assess your job-readiness with AI',
        ],
        md: [
          'Get personalized job\nlistings and practice\ninterview questions',
          'Assess your job-\nreadiness with AI',
        ],
        lg: [
          'Get personalized job listings\nand practice interview questions',
          'Assess your job-readiness\nwith AI',
        ],
        xl: [
          'Get personalized job listings\nand practice interview questions',
          'Assess your job-readiness with AI',
        ],
      },
    },
    img: {
      sources: {
        lg: `${imgBase}/v2/Career-L.svg`,
        md: `${imgBase}/v2/Career-M.svg`,
        sm: `${imgBase}/v2/Career-S.svg`,
        xl: `${imgBase}/v2/Career-XL.svg`,
        xs: `${imgBase}/v2/Career-XS.svg`,
      },
      verticallyAlign: true,
    },
  },
  {
    title: {
      copy: 'Interactive courses',
    },
    bullets: {
      copy: {
        _: [
          'Learn Python, JavaScript,\nand more',
          '400+ courses, paths, and case\nstudies for all skill levels',
        ],
        sm: [
          'Learn Python, JavaScript, and more',
          '400+ courses, paths, and case\nstudies for all skill levels',
        ],
        md: [
          'Learn Python,\nJavaScript, and more',
          '400+ courses, paths,\nand case studies for\nall skill levels',
        ],
        lg: [
          'Learn Python, JavaScript,\nand more',
          '400+ courses, paths, and\ncase studies for all skill\nlevels',
        ],
        xl: [
          'Learn Python, JavaScript, and more',
          '400+ courses, paths, and case\nstudies for all skill levels',
        ],
      },
    },
    img: {
      sources: {
        lg: `${imgBase}/v2/Interactive-Courses-L.svg`,
        md: `${imgBase}/v2/Interactive-Courses-M.svg`,
        sm: `${imgBase}/v2/Interactive-Courses-S.svg`,
        xl: `${imgBase}/v2/Interactive-courses-XL.svg`,
        xs: `${imgBase}/v2/Interactive-Courses-XS.svg`,
      },
      verticallyAlign: false,
    },
  },
  {
    title: {
      copy: 'Build AI skills',
    },
    bullets: {
      copy: {
        _: [
          'AI courses and case studies on\nChatGPT, NLP, and more',
          'AI-powered features for\nefficient learning',
        ],
        sm: [
          'AI courses and case studies\non ChatGPT, NLP, and more',
          'AI-powered features for\nefficient learning',
        ],
        md: [
          'AI courses and case studies\non ChatGPT, NLP, and more',
          'AI-powered features for\nefficient learning',
        ],
        lg: [
          'AI courses and case studies\non ChatGPT, NLP, and more',
          'AI-powered features for\nefficient learning',
        ],
        xl: [
          'AI courses and case studies\non ChatGPT, NLP, and more',
          'AI-powered features for\nefficient learning',
        ],
      },
    },
    img: {
      sources: {
        lg: `${imgBase}/v2/AI-L.svg`,
        md: `${imgBase}/v2/AI-M.svg`,
        sm: `${imgBase}/v2/AI-S.svg`,
        xl: `${imgBase}/v2/AI-XL.svg`,
        xs: `${imgBase}/v2/AI-XS.svg`,
      },
      verticallyAlign: true,
    },
  },
];

export const indiaLearnerStorySlide: CarouselItem = {
  title: {
    copy: 'OUR LEARNERS GET RESULTS',
    fontSize: {
      _: 34,
      xs: 34,
      sm: 70 as 16,
      md: 34 as 16,
      lg: 62 as 16,
      xl: 70 as 16,
    },
  },
  bullets: {
    copy: {
      _: [],
      sm: [],
      md: [],
      lg: [],
      xl: [],
    },
  },
  img: {
    sources: {
      xl: `${imgBase}/v2/Ind-Learner-Story-XL.svg`,
      lg: `${imgBase}/v2/Ind-Learner-Story-L.svg`,
      md: `${imgBase}/v2/Ind-Learner-Story-M.svg`,
      sm: `${imgBase}/v2/Ind-Learner-Story-S.svg`,
      xs: `${imgBase}/v2/Ind-Learner-Story-XS.svg`,
    },
    verticallyAlign: true,
  },
};
