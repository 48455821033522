import { Box, StrokeButton, Text } from '@codecademy/gamut';
import { css, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { useOnboardingContext } from './context/OnboardingContext';
import type { QuestionResponses, QuestionType } from './types';

export const StyledStrokeButton = styled(StrokeButton)(
  css({
    alignItems: 'baseline',
    border: 'none',
    borderRadius: 'none',
    color: 'black',
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 400,
    height: 'fit-content',
    justifyContent: 'left',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'break-spaces',
    '&:hover': {
      backgroundColor: theme.colors['gray-100'],
    },
    '&:active': {
      backgroundColor: theme.colors.black,
    },
    '&:focus-visible:before': {
      opacity: 0,
    },
    '&:focus-visible': {
      outline: '5px auto -webkit-focus-ring-color',
    },
  })
);

interface AnswerChoiceProps {
  choice: {
    id: QuestionResponses[QuestionType];
    descriptionText?: string;
    displayText: string;
  };
  questionType: QuestionType;
  onClick: Function;
}

export const AnswerChoice: React.FC<AnswerChoiceProps> = ({
  choice: { id, descriptionText, displayText },
  questionType,
  onClick,
}) => {
  const { currentIndex, handleAnswerClick } = useOnboardingContext();

  const isKnowledgeQuestion = questionType === 'knowledge';
  return (
    <Box key={id} as="li" background="white" border={1}>
      <StyledStrokeButton
        onClick={() => {
          handleAnswerClick(
            {
              [questionType]: id,
            },
            currentIndex
          );
          onClick();
        }}
        py={isKnowledgeQuestion ? 16 : 12}
      >
        <Text fontWeight={isKnowledgeQuestion ? 700 : 400}>{displayText}</Text>
        {descriptionText && <Text>{descriptionText}</Text>}
      </StyledStrokeButton>
    </Box>
  );
};
