import React, { createContext, useContext, useState } from 'react';

import { questionnaire } from '../consts';
import type { QuestionResponses, QuestionType } from '../types';

export enum QuestionStatus {
  SELECTED,
  INCOMPLETE,
  COMPLETE,
}

interface OnboardingContextType {
  currentIndex: number;
  getQuestionStatus: (k: QuestionType, i: number) => QuestionStatus;
  handleAnswerClick: (answer: QuestionResponses, i: number) => void;
  handleBackClick: (i: number, questionType: QuestionType) => void;
  isQuizComplete: boolean;
  toggleIndex: (index: number) => void;
  quizAnswers: QuestionResponses;
  resetOnboardingContext: () => void;
}

const defaultContextValue = {
  currentIndex: 0,
  getQuestionStatus: () => QuestionStatus.INCOMPLETE,
  handleAnswerClick: () => null,
  handleBackClick: () => null,
  isQuizComplete: false,
  toggleIndex: () => null,
  quizAnswers: {},
  resetOnboardingContext: () => null,
};

export const OnboardingContext =
  createContext<OnboardingContextType>(defaultContextValue);

export const OnboardingContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionState, setQuestionState] = useState<QuestionResponses>({});

  const isQuizComplete = Boolean(
    questionState.careerInterest &&
      questionState.purpose &&
      questionState.knowledge
  );

  const getQuestionStatus = (key: QuestionType, index: number) => {
    if (currentIndex === index) return QuestionStatus.SELECTED;

    return questionState[key]
      ? QuestionStatus.COMPLETE
      : QuestionStatus.INCOMPLETE;
  };

  const toggleIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const updateQuestionState = (userAnswer: QuestionResponses) => {
    setQuestionState({ ...questionState, ...userAnswer });
  };

  const handleBackClick = (index: number, questionType: QuestionType) => {
    updateQuestionState({ [questionType]: '' });
    toggleIndex(index);
  };

  const handleAnswerClick = (userAnswer: QuestionResponses, index: number) => {
    updateQuestionState(userAnswer);
    toggleIndex(Math.min(index + 1, questionnaire.length - 1));
  };

  const resetOnboardingContext = () => {
    setCurrentIndex(defaultContextValue.currentIndex);
    setQuestionState(defaultContextValue.quizAnswers);
  };

  return (
    <OnboardingContext.Provider
      value={{
        currentIndex,
        getQuestionStatus,
        handleAnswerClick,
        handleBackClick,
        isQuizComplete,
        toggleIndex,
        quizAnswers: questionState,
        resetOnboardingContext,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
