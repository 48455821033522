import { useLazyQuery } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import { logError } from '@mono/data/logging';
import { useEffect, useState } from 'react';

import type { QuestionResponses } from '../types';
import { getCareerInterestRelatedTopicsQuery } from './queries.graphql-gateway';

// export for test only
export const defaultTopics = [
  'Python',
  'JavaScript',
  'HTML & CSS',
  'Web Development',
  'Data Science',
];

export const useCareerInterestRelatedTopics = (
  careerInterest: QuestionResponses['careerInterest']
) => {
  const [relatedTopics, setRelatedTopics] = useState<string[]>([]);

  const [fetchRelatedTopics] = useLazyQuery(
    getCareerInterestRelatedTopicsQuery,
    {
      context: { service: ApolloClientService.GraphqlGateway },
    }
  );

  useEffect(() => {
    if (!careerInterest || careerInterest === "I don't know") {
      setRelatedTopics(defaultTopics);
    } else {
      const slug = careerInterest.toLowerCase().replaceAll(' ', '-');

      fetchRelatedTopics({
        variables: { slug },
      })
        .then(({ data }) => {
          const topics = data?.catalogHubPage?.relatedTopics.map(
            (topic) => topic.title
          );
          setRelatedTopics(topics ?? defaultTopics);
        })
        .catch((err) => {
          logError(err, {
            message:
              'AnonOnboardingQuestionnaire: Failed to fetch careerInterest related topics',
          });
          setRelatedTopics(defaultTopics);
        });
    }
  }, [careerInterest, fetchRelatedTopics]);

  return relatedTopics;
};
