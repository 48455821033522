import { Box, FlexBox } from '@codecademy/gamut';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';

import { CarouselItem, transitionDuration } from './consts';
import { ImageSet } from './ImageSet';

const SCROLL_THRESHOLD = 96;

const bezier = 'cubic-bezier(.5,.2,.3,1.1)';

const Slides = styled(FlexBox)<{ currentIndex: number; slide: number }>`
  & > ${Box} {
    ${({ currentIndex }) => `transform: translateX(${currentIndex * -100}%);`}
    transition: transform ${transitionDuration} ${bezier};
  }
  ${({ slide }) => `
    transform: translateX(${slide}px);
  `}
  ${({ slide }) =>
    slide === 0 &&
    `
    transition: transform ${transitionDuration} ${bezier};
  `}
`;

export const ImageSlides: React.FC<{
  carouselItems: CarouselItem[];
  currentIndex: number;
  onChange: (i: number) => void;
  swipeTargetRef: React.RefObject<HTMLDivElement>;
}> = ({ carouselItems, currentIndex, onChange, swipeTargetRef }) => {
  const [slideDistance, setSlideDistance] = useState(0);

  const initSlide = useCallback(
    (evt: TouchEvent) => {
      const slideFrom = evt.touches[0].clientX;
      let distance = 0;

      const onSlide = (evt: TouchEvent) => {
        const slideTo = evt.touches[0].clientX;
        distance = slideTo - slideFrom;

        if (currentIndex <= 0) {
          distance = Math.min(distance, 0);
        }
        if (currentIndex >= carouselItems.length - 1) {
          distance = Math.max(distance, 0);
        }
        setSlideDistance(distance);
      };

      const endSlide = () => {
        if (distance < -SCROLL_THRESHOLD) {
          onChange(currentIndex + 1);
        }
        if (distance > SCROLL_THRESHOLD) {
          onChange(currentIndex - 1);
        }
        setSlideDistance(0);
        document.removeEventListener('touchmove', onSlide);
        document.removeEventListener('touchend', endSlide);
      };

      document.addEventListener('touchmove', onSlide);
      document.addEventListener('touchend', endSlide);
    },
    [carouselItems.length, onChange, currentIndex]
  );

  useEffect(() => {
    const tgt = swipeTargetRef.current;
    tgt?.addEventListener('touchstart', initSlide);

    return () => {
      tgt?.removeEventListener('touchstart', initSlide);
    };
  }, [swipeTargetRef, initSlide]);

  return (
    <Slides
      position="relative"
      width={`${carouselItems.length * 100}%`}
      height={{
        _: 'calc(85vw + 48px)',
        sm: 'calc(70vw + 16px)',
        md: 'calc(50vw - 56px)',
        lg: 'calc(45vw + 4px)',
        xl: 552,
      }}
      mt={{ _: 48, xl: 96 }}
      mb={64}
      alignItems="flex-start"
      currentIndex={currentIndex}
      slide={slideDistance}
      aria-hidden
    >
      {carouselItems.map((item, i) => (
        <Box
          key={item.title.copy}
          width="100%"
          height="100%"
          position="relative"
        >
          <ImageSet {...item.img} position="absolute" preload={i === 0} />
        </Box>
      ))}
    </Slides>
  );
};
