import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  CurriculumCardData,
  fetchCardDataBySlugs,
} from '@mono/data/curriculum-cards';
import { fetchCacher } from '@mono/data/fetch-cachers';
import { logError } from '@mono/data/logging';

export const fetchGoalsCurriculumContentDataCached = async (
  client: ApolloClient<NormalizedCacheObject>,
  slugs: string[]
) => {
  const f = () => fetchGoalsCurriculumContentData(client, slugs);
  return fetchCacher('goals-curriculum-content', f, {
    revalidateMs: 60 * 60 * 1000, // 1 hour
  });
};

export const fetchGoalsCurriculumContentData = async (
  client: ApolloClient<NormalizedCacheObject>,
  slugs: string[]
) => {
  if (!slugs.length) {
    return {};
  }

  try {
    const data = await fetchCardDataBySlugs(slugs, client, {
      skipEnrollmentStatus: true,
    });

    return data.reduce<Record<string, CurriculumCardData>>(
      (acc, item) => ({
        ...acc,
        [item.slug]: item,
      }),
      {}
    );
  } catch (error) {
    logError(error, {
      message: `Unable to fetch curriculum card data for goals`,
    });
    return {};
  }
};
