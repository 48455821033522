import { Box, FillButton, Text } from '@codecademy/gamut';
import { LogoFromSkillsoft } from '@mono/brand';
import React from 'react';

import { trackUserClick } from '../../tracking';

export const LegacyBusinessCTA: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Box py={{ _: 40, xs: 96 }}>
    <LogoFromSkillsoft height="3rem" color="white" />
    <Text as="h2" py={16}>
      Level up your team&apos;s skills
    </Text>
    <Text as="p" pb={16}>
      Give your team the knowledge, experience, and confidence they need to
      tackle any problem.
    </Text>
    <FillButton
      onClick={() =>
        trackUserClick({
          context: 'homepage',
          target: 'cfb_explore',
        })
      }
      href="/business"
    >
      Explore business plans
    </FillButton>
  </Box>
);
