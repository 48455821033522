import { Box, FillButton, Text } from '@codecademy/gamut';
import { Logo } from '@mono/brand';
import React from 'react';

import { trackUserClick } from '../../tracking';

export const BusinessCTA: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Box py={{ _: 40, md: 96 }}>
    <Logo type="teams" height={24} color="white" />
    <Text as="h2" py={16}>
      Equip your team with skills for today — and tomorrow
    </Text>
    <Text as="p" pb={16}>
      Reach business and professional goals with technical training for your
      whole team.
    </Text>
    <FillButton
      onClick={() =>
        trackUserClick({
          context: 'homepage',
          target: 'cfb_explore',
        })
      }
      href="/business"
    >
      Explore business plans
    </FillButton>
  </Box>
);
