export enum Product {
  Basic = 'free',
  Pro = 'pro',
  Teams = 'teams',
  Silver = 'pro-silver',
  Gold = 'pro-gold',
}

export interface ProductDetails {
  isTrialPlan?: boolean | null | undefined;
  price: string;
  planId?: string | null | undefined;
  displayName: string;
  planType: string;
  savings?: string | null | undefined;
  includedTaxRate?: string | null | undefined;
}

export type PlanType = 'basic' | 'pro-silver' | 'pro-gold' | 'teams';

export type CtaConfig = Partial<
  Record<
    PlanType,
    {
      label?: string;
      href?: string;
      onClick?: () => void;
    }
  >
>;

export type CopyConfig = Partial<Record<PlanType, { description: string }>>;

export interface User {
  anonymous?: boolean;
  proSilver?: boolean;
  proGold?: boolean;
}
