export const scrollToAnchor = (
  anchorRef: React.MutableRefObject<HTMLDivElement | null>,
  offset: number
) => {
  if (anchorRef.current) {
    const { offsetTop } = anchorRef.current;
    const step = () => {
      window.scrollTo(0, offsetTop + offset);
    };
    window.requestAnimationFrame(step);
  }
};
