export const show404 = { notFound: true } as const;

export const redirect = (destination: string, permanent = false) => {
  return {
    redirect: {
      destination,
      permanent,
    },
  };
};
