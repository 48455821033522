import {
  Box,
  FillButton,
  FlexBox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@codecademy/gamut';
import { DiagonalBDense } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { getCatalogBasePath } from '@mono/util/url';
import uniqueId from 'lodash/uniqueId';

import { trackUserClick } from '../../../tracking';
import { Goal } from '..';
import { DesktopCards } from './DesktopCards';

const HyperPattern = styled(DiagonalBDense)`
  color: ${theme.colors.hyper};
`;

interface DesktopGoalSectionProps {
  goals: Goal[];
  goalState: [number, React.Dispatch<React.SetStateAction<number>>];
}

export const DesktopGoalSection: React.FC<
  React.PropsWithChildren<DesktopGoalSectionProps>
> = ({ goals, goalState }) => {
  const [currentTabIndex, setCurrentTabIndex] = goalState;

  return (
    <Box pb={32} mx="auto">
      <HyperPattern
        width="100%"
        position="absolute"
        height="35%"
        bottom="0"
        left="0"
        data-testid="desktop-gs-hyperpattern"
        pointerEvents="none"
      />
      <Tabs
        index={currentTabIndex}
        onChange={(index: number) => {
          setCurrentTabIndex(index);
          trackUserClick({
            target: goals[index].trackingTitle,
            context: 'homepage_content_goals',
          });
        }}
      >
        <TabList my={32} mx="auto" width="fit-content">
          {goals.map((goal) => (
            <Tab key={uniqueId('tab')}>{goal.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {goals.map((goal) => (
            <TabPanel tabIndex={-1} key={uniqueId('panel')}>
              <DesktopCards content={goal.relatedContent} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <FlexBox mt={32} justifyContent="center">
        <FillButton
          href={getCatalogBasePath()}
          onClick={() =>
            trackUserClick({
              target: 'catalog',
              context: 'homepage_browse_catalog',
            })
          }
        >
          Explore Full Catalog
        </FillButton>
      </FlexBox>
    </Box>
  );
};
