import {
  Box,
  ContentContainer,
  FillButton,
  FlexBox,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';
import { PausableImage } from '@mono/brand';
import { useEverInView } from '@mono/util/dom';
import {
  TabPanel,
  TabPanels,
  Tabs,
  TabsKeyboardActivation,
  TabsOrientation,
} from '@reach/tabs';
import React, { useEffect, useState } from 'react';

import {
  trackingPageName,
  trackUserClick,
  trackUserImpression,
} from '../../tracking';
import {
  PlatformShowcaseSection,
  platformShowcaseTrackingBase,
} from './consts';
import {
  AnimatedTabDescription,
  AnimatedTabHeader,
  ExpandableTab,
  TabWrapper,
  VerticalTabList,
} from './styles';

export const PlatformShowcase: React.FC<PlatformShowcaseSection> = ({
  title,
  eyebrow,
  platformFeatures,
  ctas,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { everInView, ref } = useEverInView('-64px', 0.35);

  useEffect(() => {
    if (everInView) {
      trackUserImpression({
        page_name: trackingPageName,
        context: 'platform_showcase',
        target: 'platform_showcase',
      });
    }
  }, [everInView]);

  const renderCtas = () => {
    if (!ctas.length) {
      return null;
    }

    return (
      <>
        <FillButton
          href={ctas[0].href}
          onClick={() =>
            trackUserClick({
              ...platformShowcaseTrackingBase,
              target: 'sign_up',
            })
          }
          width={{ _: 190, lg: 181 }}
          role="button"
        >
          {ctas[0].copy}
        </FillButton>
        {ctas.length > 1 && (
          <TextButton
            href={ctas[1].href ?? undefined}
            role="button"
            ml={16}
            target="_blank"
            onClick={() => {
              trackUserClick({
                ...platformShowcaseTrackingBase,
                target: 'explore_features',
              });
            }}
          >
            {ctas[1]?.copy ?? ''}
          </TextButton>
        )}
      </>
    );
  };

  return (
    <Background position="relative" bg="navy" ref={ref}>
      <ContentContainer py={64}>
        <Text fontFamily="accent" as="p" textAlign="center">
          {eyebrow}
        </Text>
        <Text as="h2" textAlign="center">
          {title}
        </Text>
        <Tabs
          index={activeTabIndex}
          onChange={(index: number) => {
            setActiveTabIndex(index);
            trackUserClick({
              ...platformShowcaseTrackingBase,
              target: platformFeatures[index].trackingTitle,
            });
          }}
          orientation={TabsOrientation.Vertical}
          keyboardActivation={TabsKeyboardActivation.Manual}
        >
          <FlexBox
            mt={32}
            gap={{ _: 32, lg: 64 }}
            flexDirection={{ _: 'column-reverse', md: 'row' }}
          >
            <Box maxWidth={{ _: '100%', md: 355, lg: 368, xl: 462 }} zIndex={1}>
              <VerticalTabList>
                {platformFeatures.map(({ title, description }, i) => {
                  const isActiveTab = i === activeTabIndex;
                  return (
                    <TabWrapper isActiveTab={isActiveTab} key={`tab-${title}`}>
                      <ExpandableTab aria-expanded={isActiveTab}>
                        <AnimatedTabHeader
                          fontWeight="bold"
                          fontSize={20}
                          isWithinActiveTab={isActiveTab}
                          aria-label={`${title} ${isActiveTab ? ':' : ''} `}
                          role="button"
                        >
                          {title}
                        </AnimatedTabHeader>
                        <AnimatedTabDescription
                          color="text-secondary"
                          fontSize={16}
                          isWithinActiveTab={isActiveTab}
                          aria-hidden={!isActiveTab}
                        >
                          {description}
                        </AnimatedTabDescription>
                      </ExpandableTab>
                    </TabWrapper>
                  );
                })}
              </VerticalTabList>
            </Box>
            <TabPanels style={{ flex: 1, zIndex: 1 }}>
              {platformFeatures.map(({ title, image }) => (
                <TabPanel key={`tabpanel-${title}`}>
                  <Box
                    borderWidth={1}
                    border="solid"
                    borderColor="white-400"
                    borderRadius="lg"
                    width="100%"
                    height="auto"
                    overflow="hidden"
                    zIndex={1}
                  >
                    <PausableImage src={image.src} alt={image.alt} />
                  </Box>
                </TabPanel>
              ))}
            </TabPanels>
          </FlexBox>
        </Tabs>
        <FlexBox mt={48} justifyContent="center">
          {renderCtas()}
        </FlexBox>
      </ContentContainer>
    </Background>
  );
};
