import { getHomepageProps, Homepage, HomepageProps } from '@mono/homepage';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Graph } from 'schema-dts';

import { PortalPage } from '~/components/PortalPage';
import { getServerSidePropsWrapper } from '~/libs/nextJs/getServerSidePropsWrapper';

export default (props: HomepageProps) => {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH;

  const pageSchema: Graph = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        name: 'Codecademy',
        url: basePath,
        description:
          'Learn the technical skills to get the job you want. Join over 50 million people choosing Codecademy to start a new career (or advance in their current one).',
        logo: 'https://static-assets.codecademy.com/Courses/Learn-Redux/matching-game/codecademy_logo.png',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'New York',
          addressRegion: 'NY',
          addressCountry: 'USA',
          postalCode: '10012',
          streetAddress: '575 Broadway, 5th Floor',
        },
        contactPoint: [
          {
            '@type': 'ContactPoint',
            contactType: 'customer service',
            url: 'https://help.codecademy.com/hc/en-us',
          },
        ],
        sameAs: [
          'https://twitter.com/Codecademy',
          'https://www.youtube.com/c/codecademy',
          'https://www.facebook.com/codecademy',
          'https://www.linkedin.com/company/codecademy',
          'https://www.instagram.com/codecademy/',
        ],
      },
      {
        '@type': 'WebSite',
        name: 'Codecademy',
        url: basePath,
        potentialAction: {
          '@type': 'SearchAction',
          target: `${basePath}/search?query={search_term_string}`,
          query: 'required',
        },
      },
    ],
  };

  return (
    <PortalPage
      backgroundColor="beige"
      meta={{
        description:
          'Learn the technical skills to get the job you want. Join over 50 million people choosing Codecademy to start a new career (or advance in their current one).',
        title: 'Learn to Code - for Free',
        pageSchema: jsonLdScriptProps(pageSchema),
      }}
      tracking={{ page_name: 'homepage' }}
      skipToContentId="heading"
    >
      <Homepage {...props} />
    </PortalPage>
  );
};

export const getServerSideProps = getServerSidePropsWrapper<HomepageProps>(
  getHomepageProps,
  {
    warmApolloCacheOptions: {
      brandedBanner: true,
    },
    initializeOptimizely: true,
    passUserInContext: true,
    enableCDNCachingForPage: false,
    cdnMaxAge: 3600,
    cdnCacheTag: 'type:html,service:portal-app,page:homepage',
  }
);
