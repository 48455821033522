import {
  Box,
  ContentContainer,
  FlexBox,
  StrokeButton,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';
import { useEverInView } from '@mono/util/dom';
import dynamic from 'next/dynamic';
import React from 'react';

import { trackingPageName, trackUserClick } from '../../tracking';
import { CodeEditorGrid } from './styles';

// we cannot ssr here because this component relies on Monaco
// which is not available in the React-Renderer.
const AsyncCodeByteEditor = dynamic(() => import('./codebyte'), {
  ssr: false,
});

const code = `
// Add your own text between
// the quotation marks.

const firstName = "Ada";
const lastName = "Lovelace";

// Now run your code to see
// a personalized message.

console.log(
\`Welcome, \${firstName} \${lastName}!\`
);
`;

export const CodingEnvironment: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const trackClick = (target: string) =>
    trackUserClick({
      target,
      context: 'homepage_code_editor',
    });

  const snippetsBaseUrl =
    process.env.NEXT_PUBLIC_ENV === 'production'
      ? 'propeller.cc-le-cf.com'
      : 'staging-propeller.cc-le-cf.com';

  const { ref, everInView } = useEverInView();

  return (
    <Background bg="navy">
      <ContentContainer>
        <CodeEditorGrid>
          <FlexBox
            ref={ref}
            gridArea="cta"
            column
            justifyContent="center"
            maxWidth={400}
            py={32}
            pl={{ _: 32, lg: 0 }}
          >
            <Text fontFamily="accent" pb={16}>
              Beginners welcome
            </Text>
            <Text as="h2" pb={16}>
              Start coding in seconds
            </Text>
            <Text as="p" pb={16}>
              Go ahead, give it a try. Our hands-on learning environment means
              you&apos;ll be writing real code from your very first lesson.
            </Text>
            <FlexBox>
              <StrokeButton
                mt={8}
                href="/courses/welcome-to-codecademy/lessons/welcome-to-codecademy/exercises/welcome-1"
                onClick={() => trackClick('course')}
              >
                Continue lesson
              </StrokeButton>
              <TextButton
                mt={8}
                ml={8}
                href="/catalog/subject/code-foundations"
                onClick={() => trackClick('related_courses')}
              >
                More beginner courses
              </TextButton>
            </FlexBox>
          </FlexBox>
          <Box pt={{ _: 32, lg: 0 }} gridArea="editor">
            {everInView && (
              <AsyncCodeByteEditor
                text={code}
                language="javascript"
                snippetsBaseUrl={snippetsBaseUrl}
                trackingData={{
                  page_name: trackingPageName,
                  context: 'homepage',
                }}
                height="27rem"
                width="100%"
                hideCopyButton
              />
            )}
          </Box>
        </CodeEditorGrid>
      </ContentContainer>
    </Background>
  );
};
