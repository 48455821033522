import { FlexBox, Text } from '@codecademy/gamut';
import React from 'react';

import loadingCardsGif from './loader_cards.gif';

interface LoadingCardsProps {
  firstMessage: string;
}

export const LoadingCards: React.FC<LoadingCardsProps> = ({ firstMessage }) => (
  <FlexBox
    flexDirection="column"
    justifyContent="center"
    width="100%"
    minWidth="50vw"
  >
    <img
      src={loadingCardsGif}
      alt="Loading..."
      style={{
        alignSelf: 'center',
        objectFit: 'contain',
        position: 'relative',
      }}
      width={320}
      height={250}
    />
    <Text alignSelf="center" textAlign="center" aria-live="polite">
      {firstMessage}
    </Text>
  </FlexBox>
);
