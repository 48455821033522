import { Box, FlexBox } from '@codecademy/gamut';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

interface MarketingToasterProps {
  toasts: {
    [key: string]: React.ReactNode;
  };
}

export const MarketingToaster: React.FC<MarketingToasterProps> = ({
  toasts,
}) => {
  return (
    <AnimatePresence>
      <motion.div
        key="marketing_toast"
        initial={false}
        animate={{ opacity: 1, left: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <OuterBox
          zIndex={2}
          position="fixed"
          justifyContent="flex-end"
          alignItems="flex-end"
          top={0}
          width="100vw"
          height="100vh"
          flexDirection="column"
          pb={64}
          pr={224 as 0}
        >
          {Object.keys(toasts).map((key) => {
            return (
              <InnerBox mt={8} mb={8} mx={24} key={key}>
                {toasts[key]}
              </InnerBox>
            );
          })}
        </OuterBox>
      </motion.div>
    </AnimatePresence>
  );
};

const OuterBox = styled(FlexBox)`
  pointer-events: none;
`;

const InnerBox = styled(Box)`
  pointer-events: all;
`;
