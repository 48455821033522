import { FlexBox, HiddenText, Text } from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { Product } from '../types';

const getCurrencySymbol = ({ isUserInIndia }: { isUserInIndia: boolean }) =>
  isUserInIndia ? '₹' : '$';

const Amount = styled(Text)`
  font-weight: bold;
  color: black;
  font-size: 64px;
  line-height: 64px;
  ${theme.breakpoints.xl} {
    font-size: 64px;
    line-height: 80px;
  }
`;

interface PricingAmountProps {
  product: Product;
  price: string;
  isUserInIndia: boolean;
  monthlyPrice?: string;
  termMonths?: number;
}

export const PricingAmount: React.FC<PricingAmountProps> = ({
  monthlyPrice,
  product,
  price,
  isUserInIndia,
  termMonths,
}) => {
  const cycle = termMonths === 1 ? 'monthly' : 'annually';
  const [dollars, cents] = price.split('.');
  const currencySymbol = getCurrencySymbol({ isUserInIndia });

  let ariaLabel = `${currencySymbol}${dollars}${cents ? `.${cents}` : ''}`;

  if (product !== Product.Basic) {
    ariaLabel += ` per month`;
  }

  return (
    <FlexBox
      data-testid="pricing-amount"
      lineHeight="title"
      mb={8}
      column
      alignItems="center"
    >
      <FlexBox mr={4} alignItems="center">
        <HiddenText>{ariaLabel}</HiddenText>
        <Text fontWeight="bold" fontSize={34} aria-hidden>
          {currencySymbol}
        </Text>
        <Amount aria-hidden>{`${dollars}`}</Amount>
        {product === Product.Basic ? null : (
          <FlexBox column ml={4} aria-hidden>
            <Text fontWeight="bold" fontSize={22}>
              {cents ? `.${cents}` : <span>&nbsp;</span>}
            </Text>
            <Text color="text-secondary">/mo</Text>
          </FlexBox>
        )}
      </FlexBox>
      <Text color="text-secondary" center mt={{ _: 8, xl: 0 }}>
        {product === Product.Basic ? (
          <Text mb={24}>Always free</Text>
        ) : (
          <FlexBox column>
            <Text mb={8}>{`Billed ${cycle} or`}</Text>
            {monthlyPrice && (
              <Text>{`${currencySymbol}${monthlyPrice} billed monthly`}</Text>
            )}
          </FlexBox>
        )}
      </Text>
    </FlexBox>
  );
};
