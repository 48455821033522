import {
  Box,
  ContentContainer,
  FlexBox,
  GridBox,
  Text,
} from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';

import { LearnerCard } from './LearnerCard';
import learnersData from './learnersData.json';

export const HikeSection: React.FC = () => (
  <Background position="relative" bg="navy">
    <ContentContainer pt={64} pb={64}>
      <Text as="h2" textAlign="center">
        Real learners, real results
      </Text>
      <Box position="relative" pb={{ _: 32, sm: 64 }} mt={40}>
        <FlexBox center>
          <GridBox
            gridTemplateColumns={{
              _: '1fr',
              sm: 'repeat(2, 1fr)',
              xl: 'repeat(3, 1fr)',
            }}
            gap={{ _: 8, sm: 16, md: 24, xl: 32 }}
          >
            {learnersData?.map((learner) => (
              <LearnerCard key={learner.id} learner={learner} />
            ))}
          </GridBox>
        </FlexBox>
      </Box>
    </ContentContainer>
  </Background>
);
