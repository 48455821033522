import { TextButton } from '@codecademy/gamut';
import React from 'react';

import { useOnboardingContext } from './context/OnboardingContext';
import type { QuestionType } from './types';

export const BackButton: React.FC<{
  questionType: QuestionType;
}> = ({ questionType }) => {
  const { currentIndex, handleBackClick } = useOnboardingContext();

  return (
    <TextButton
      onClick={() => {
        handleBackClick(currentIndex - 1, questionType);
      }}
    >
      Back
    </TextButton>
  );
};
