import { createTracker } from '@mono/tracking';
import { isOnServer } from '@mono/util/env';

export const trackingPageName = 'homepage';

const tracker = createTracker({
  apiBaseUrl: isOnServer() ? '' : process.env.NEXT_PUBLIC_BASE_PATH,
  verbose: !['production', 'test'].includes(process.env.NODE_ENV),
});

const baseTrackingData = {
  source_codebase: 'portal-app',
  page_name: trackingPageName,
};

export const trackEvent: typeof tracker.event = (category, event, data) =>
  tracker.event(category, event, { ...baseTrackingData, ...data });

export const trackUserClick: typeof tracker.click = (data) =>
  tracker.click({ ...baseTrackingData, ...data });

export const trackUserVisit: typeof tracker.visit = (data) =>
  tracker.visit({ ...baseTrackingData, ...data });

export const trackUserImpression: typeof tracker.impression = (data) =>
  tracker.impression({ ...baseTrackingData, ...data });
