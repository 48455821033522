import { Box, FlexBox } from '@codecademy/gamut';
import { breakpoints } from '@codecademy/gamut-styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { trackUserClick, trackUserImpression } from '../../../tracking';
import { CarouselCopy } from './CarouselCopy';
import { CarouselItem } from './consts';
import { Controls } from './Controls';
import { ImageSlides } from './ImageSlides';

const evalIsMobile = () => {
  if (typeof window === 'undefined') {
    return true;
  }
  return window.innerWidth <= parseInt(breakpoints.md.replace('px', ''), 10);
};

export const Carousel: React.FC<{
  carousel: CarouselItem[];
}> = ({ carousel }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(evalIsMobile());
  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const { inView, ref } = useInView({ threshold: 0.35 });
  const swipeTargetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = () => setIsMobile(evalIsMobile());
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    if (!isMobile && !isPaused && !isFocused && !isHover) {
      const t = setInterval(() => {
        setCurrentIndex((state) => {
          const nextState = (state + 1) % carousel.length;

          if (inView) {
            trackUserImpression({
              page_name: 'homepage',
              context: 'carousel',
              target: `slide_${nextState}`,
            });
          }
          return nextState;
        });
      }, 6000);
      return () => clearInterval(t);
    }
    return () => null;
  }, [isMobile, isPaused, isFocused, isHover, inView, carousel]);

  const trackClick = (i: number) => {
    trackUserClick({
      page_name: 'homepage',
      context: 'carousel',
      target: `slide_${i}`,
    });
  };

  const setSwipeTargetRefs = useCallback(
    (node: HTMLDivElement) => {
      swipeTargetRef.current = node;
      ref(node);
    },
    [ref]
  );

  return (
    <Box
      as="section"
      aria-label="hero carousel"
      position="relative"
      overflow="hidden"
      width="100%"
      height="100%"
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      aria-live={isFocused ? 'polite' : 'off'}
      ref={setSwipeTargetRefs}
    >
      <ImageSlides
        carouselItems={carousel}
        currentIndex={currentIndex}
        onChange={(i) => {
          setCurrentIndex(i);
          trackClick(i);
        }}
        swipeTargetRef={swipeTargetRef}
      />

      <CarouselCopy carouselItems={carousel} currentIndex={currentIndex} />

      <FlexBox
        justifyContent="center"
        position="absolute"
        bottom={32}
        width="100%"
      >
        <Controls
          carouselItems={carousel}
          currentIndex={currentIndex}
          onChange={(i) => {
            setCurrentIndex(i);
            setIsPaused(true);
            trackClick(i);
          }}
          onPause={() => setIsPaused((state) => !state)}
          isPaused={isPaused}
        />
      </FlexBox>
    </Box>
  );
};
