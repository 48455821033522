import { FillButton, StrokeButton } from '@codecademy/gamut';

import { CtaConfig, Product } from './types';

const getDefaultCtaConfig = (product: Product) => {
  switch (product) {
    case Product.Basic:
      return {
        label: 'Get free account',
        href: '/register',
      };
    case Product.Silver:
      return {
        label: 'Try Plus for free',
        href: '/checkout?plan_id=proSilverAnnualV2_7trial&plan_type=plus',
      };
    case Product.Gold:
    case Product.Pro:
      return {
        label: 'Try Pro for free',
        href: '/checkout?plan_id=proGoldAnnualV2_7trial&plan_type=pro',
      };
    default:
      return {
        label: 'Try Pro for free',
        href: '/checkout',
      };
  }
};

export const ProductCTA = ({
  product,
  ctaConfig,
  isCurrentPlan,
}: {
  product: Product;
  ctaConfig: CtaConfig[keyof CtaConfig];
  isCurrentPlan?: boolean;
}) => {
  const defaultCtaConfig = getDefaultCtaConfig(product);

  const ButtonComponent =
    product === Product.Gold || product === Product.Pro
      ? FillButton
      : StrokeButton;

  return (
    <ButtonComponent
      mt={8}
      href={ctaConfig?.href || defaultCtaConfig.href}
      onClick={ctaConfig?.onClick}
      mx={24}
      disabled={isCurrentPlan}
    >
      {(isCurrentPlan && 'Your current plan') ||
        ctaConfig?.label ||
        defaultCtaConfig.label}
    </ButtonComponent>
  );
};
