import { Box, ContentContainer } from '@codecademy/gamut';
import { DotLoose } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

export const PatternBorderContainer: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const contentAndHeaderHeight = `${contentHeight}px + ${theme.elements.headerHeight}`;
  const overflow = windowHeight - contentHeight;
  const posOverFlow = overflow < 0 ? overflow * -1 : overflow;

  useEffect(() => {
    setContentHeight(contentRef.current?.clientHeight || 0);
  }, [windowHeight, windowWidth]);

  return (
    <>
      <Box
        width="100vw"
        pl={3.25 as 0}
        height={{
          _: `calc(${contentAndHeaderHeight} + 12rem)`,
          xs: `calc(${contentAndHeaderHeight} + 11rem)`,
          sm:
            windowHeight < 665 // 665 is roughly the minheight of the content below (533) + header height + some padding
              ? `calc(${contentAndHeaderHeight} + ${posOverFlow}px)`
              : `calc(100vh - ${theme.elements.headerHeight})`,
        }}
        position="absolute"
        zIndex={-1}
      >
        <DotLoose />
      </Box>

      <ContentContainer py={64}>
        <Box ref={contentRef} minHeight={{ _: 0, sm: 370 }} maxWidth="inherit">
          {children}
        </Box>
      </ContentContainer>
    </>
  );
};
