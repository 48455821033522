import { Box, FlexBox, HiddenText, Text } from '@codecademy/gamut';
import { CheckIcon } from '@codecademy/gamut-icons';
import { theme } from '@codecademy/gamut-styles';
import React, { forwardRef } from 'react';

import {
  QuestionStatus,
  useOnboardingContext,
} from '../context/OnboardingContext';
import type { QuestionType } from '../types';

interface QuestionToggleProps {
  index: number;
  questionKey: QuestionType;
}

const BeigeCheck = forwardRef<SVGSVGElement>((_props, ref) => (
  <CheckIcon ref={ref} size={16} color="beige-100" />
));

export const QuestionToggle: React.FC<QuestionToggleProps> = ({
  questionKey,
  index,
}) => {
  const { getQuestionStatus } = useOnboardingContext();

  const status = getQuestionStatus(questionKey, index);

  if (status === QuestionStatus.COMPLETE)
    return (
      <FlexBox
        style={{
          background: theme.colors['navy-800'],
          cursor: 'default',
          pointerEvents: 'none',
        }}
        borderRadius="full"
        height={32}
        width={32}
        justifyContent="center"
        alignItems="center"
      >
        <HiddenText>{`Question ${index + 1} answered`}</HiddenText>
        <BeigeCheck />
      </FlexBox>
    );

  if (status === QuestionStatus.INCOMPLETE)
    return (
      <Box
        aria-label={`Question ${index + 1}`}
        aria-disabled
        style={{
          cursor: 'default',
          pointerEvents: 'none',
        }}
        border={1}
        borderRadius="full"
        borderColor="navy-500"
        height={32}
        width={32}
        pt={4}
        textAlign="center"
        color="navy-500"
        fontSize={18}
      >
        <Text>{`${index + 1}`}</Text>
      </Box>
    );

  return (
    <Box
      aria-label={`Question ${index + 1}`}
      aria-current="true"
      style={{
        background: theme.colors.beige,
        cursor: 'default',
        pointerEvents: 'none',
        fontWeight: 'bold',
        fontSize: '18px',
      }}
      borderWidth={2}
      border={1}
      borderRadius="full"
      borderColor="navy"
      height={32}
      width={32}
      pt={2 as 0}
      textAlign="center"
      color="navy"
    >
      {`${index + 1}`}
    </Box>
  );
};
