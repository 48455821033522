import { Column, ContentContainer, LayoutGrid } from '@codecademy/gamut';
import { Background } from '@codecademy/gamut-styles';
import React from 'react';

import teamsImage from './assets/Teams.svg';
import { LegacyBusinessCTA } from './LegacyBusinessCTA';

export const LegacyBusinessBanner: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Background bg="navy">
    <ContentContainer>
      <LayoutGrid>
        <Column offset={{ _: 0, xs: 1 }} size={{ _: 12, xs: 4 }}>
          <LegacyBusinessCTA />
        </Column>
        <Column
          offset={{ _: 0, xs: 7 }}
          size={{ _: 12, xs: 5, sm: 4 }}
          m="auto"
          pb={{ _: 32, xs: 0 }}
        >
          <img src={teamsImage} alt="" />
        </Column>
      </LayoutGrid>
    </ContentContainer>
  </Background>
);
