import { GridBox } from '@codecademy/gamut';

import { getProductDetails, PlansByType } from './config';
import { PricingCard } from './PricingCard';
import { CopyConfig, CtaConfig, Product, User } from './types';

interface ProductsProps {
  plansByType: PlansByType;
  ctaConfig?: CtaConfig;
  copyConfig?: CopyConfig;
  user?: User;
  isUserInIndia: boolean;
}

export const Products: React.FC<ProductsProps> = ({
  plansByType,
  ctaConfig,
  copyConfig,
  user,
  isUserInIndia,
}) => {
  const productsLength = Object.keys(plansByType).length;

  const renderCard = (product: Product) => {
    switch (product) {
      case Product.Basic:
        return (
          <PricingCard
            key={product}
            product={Product.Basic}
            productDetails={getProductDetails(plansByType, product)}
            plansByType={plansByType}
            isCurrentPlan={
              user && !user.anonymous && !user.proSilver && !user.proGold
            }
            isUserInIndia={isUserInIndia}
            ctaConfig={ctaConfig?.basic}
            copyConfig={copyConfig?.basic}
          />
        );

      case Product.Silver:
        return (
          <PricingCard
            key={product}
            product={Product.Silver}
            productDetails={getProductDetails(plansByType, product)}
            plansByType={plansByType}
            isCurrentPlan={user?.proSilver}
            isUserInIndia={isUserInIndia}
            ctaConfig={ctaConfig?.['pro-silver']}
            copyConfig={copyConfig?.['pro-silver']}
          />
        );

      case Product.Gold:
        return (
          <PricingCard
            key={product}
            product={Product.Gold}
            productDetails={getProductDetails(plansByType, product)}
            plansByType={plansByType}
            isCurrentPlan={user?.proGold}
            isUserInIndia={isUserInIndia}
            ctaConfig={ctaConfig?.['pro-gold']}
            copyConfig={copyConfig?.['pro-gold']}
          />
        );

      default:
        return null;
    }
  };

  return (
    <GridBox
      as="ul"
      pt={24}
      px={0}
      mb={0}
      center
      fit
      gridAutoFlow={{ _: 'row', md: 'column' }}
      columnGap={24}
      rowGap={24}
      alignItems={{ _: 'unset', md: 'stretch' }}
      justifyItems="center"
      gridTemplateColumns={{
        _: '1fr',
        md: `repeat(${productsLength}, ${
          productsLength <= 2 ? 'max-content' : '1fr'
        })`,
      }}
    >
      {Object.keys(plansByType).map(renderCard)}
    </GridBox>
  );
};
