import { Column, FlexBox, LayoutGrid } from '@codecademy/gamut';
import { VariableCurriculumCard } from '@mono/ui/components';
import React from 'react';

import { trackingPageName } from '../../../tracking';
import { RelatedContent } from '..';
import { LinkedHubCard } from '../LinkedHubCard';

const DESKTOP_CARD_HEIGHT = 302;

interface DesktopCardsProps {
  content: RelatedContent;
}

export const DesktopCards: React.FC<
  React.PropsWithChildren<DesktopCardsProps>
> = ({ content: { courses, paths, categories } }) => {
  return (
    <LayoutGrid columnGap={24} as="ul">
      {courses.map((course) => (
        <Column size={3} key={course.slug} as="li">
          <FlexBox height={DESKTOP_CARD_HEIGHT} width="auto">
            <VariableCurriculumCard
              content={course}
              key={course.id}
              trackingData={{
                page_name: trackingPageName,
                context: 'homepage_content_link',
              }}
            />
          </FlexBox>
        </Column>
      ))}
      {paths.map((path) => (
        <Column size={3} key={path.slug} as="li">
          <FlexBox height={DESKTOP_CARD_HEIGHT} width="auto">
            <VariableCurriculumCard
              content={path}
              key={path.id}
              trackingData={{
                page_name: trackingPageName,
                context: 'homepage_content_link',
              }}
            />
          </FlexBox>
        </Column>
      ))}
      {categories.map((category) => (
        <Column size={3} key={category.slug} as="li">
          <LinkedHubCard
            item={category}
            trackingData={{
              page_name: trackingPageName,
              context: 'homepage_content_link',
            }}
          />
        </Column>
      ))}
    </LayoutGrid>
  );
};
