import { gql } from '@mono/data/codegen/client/graphql-gateway';

export const getCareerInterestRelatedTopicsQuery = gql(`
  query onboardingQuizCareerInterestRelatedTopics($slug: String!) {
    catalogHubPage(slug: $slug) {
      relatedTopics {
        title
      }
    }
  }
`);
