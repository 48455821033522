import { pxRem } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

export const StyledImage = styled.img`
  max-width: 100%;
  margin-bottom: ${pxRem(60)};
  margin-top: ${pxRem(16)};

  ${({ theme }) => theme.breakpoints.xs} {
    max-width: ${pxRem(220)};
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.sm} {
    max-width: ${pxRem(250)};
  }

  ${({ theme }) => theme.breakpoints.md} {
    max-width: ${pxRem(356)};
    max-height: ${pxRem(328)};
  }

  ${({ theme }) => theme.breakpoints.lg} {
    max-width: ${pxRem(450)};
  }
`;
