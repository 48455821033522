import { Box } from '@codecademy/gamut';
import { breakpoints as bp, type MediaSize } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import Head from 'next/head';
import { type ComponentProps, Fragment } from 'react';

const Picture = styled(Box)`
  display: flex;
  user-select: none;
  pointer-events: none;
`.withComponent('picture');

const Img = styled.img`
  width: 100%;
  z-index: -1;
`;

type ImageProps = ComponentProps<typeof Picture>;
export type ImageSetProps = ImageProps & {
  sources: Record<MediaSize, string>;
  preload?: boolean;
  verticallyAlign: boolean;
};

const pxToNum = (px: string) => +px.replace('px', '');

// explicit min and max must be set to avoid matching multiple link preload cases
const mediaQueries = {
  xl: `(min-width: ${bp.xl})`,
  lg: `(min-width: ${bp.lg}) and (max-width: ${pxToNum(bp.xl) - 1}px)`,
  md: `(min-width: ${bp.md}) and (max-width: ${pxToNum(bp.lg) - 1}px)`,
  sm: `(min-width: ${bp.sm}) and (max-width: ${pxToNum(bp.md) - 1}px)`,
  xs: `(max-width: ${pxToNum(bp.sm) - 1}px)`,
};

export const ImageSet = ({
  sources,
  preload,
  verticallyAlign,
  ...imgProps
}: ImageSetProps) => (
  <Picture
    {...imgProps}
    top={
      verticallyAlign
        ? { _: 8, sm: 44, lg: 48, xl: 40 }
        : { _: -48, sm: 0, md: -48, lg: 0 }
    }
    mr={{ _: 32, sm: 48, md: 12, lg: 48 }}
    left={verticallyAlign ? { _: 32, sm: 112, md: 24, lg: 96 } : 0}
    height="100%"
  >
    {Object.keys(sources).map((size: MediaSize) => (
      <Fragment key={size}>
        {preload && (
          // preload to improve LCP, use sparingly for prominent images above the fold
          <Head>
            <link
              rel="preload"
              href={sources[size]}
              as="image"
              media={mediaQueries[size]}
            />
          </Head>
        )}
        <source media={mediaQueries[size]} srcSet={sources[size]} />
      </Fragment>
    ))}
    <Img alt="" src={sources.xs} />
  </Picture>
);
