import { Product, ProductDetails } from './types';

type Period = 'annual' | 'monthly';
type PlansByPeriod = Record<Period, ProductDetails>;
export type PlansByType = Record<Product, PlansByPeriod>;

export const getProductDescription = (product: Product) => {
  switch (product) {
    case Product.Basic:
      return 'Start learning something new with basic access';
    case Product.Teams:
      return '';
    case Product.Pro:
    case Product.Silver:
      return 'Build in-demand technical skills for work or a personal project';
    case Product.Gold:
      return 'Develop the skills and experience to land a job in tech';
  }
};

export const getProductDetails = (
  products: PlansByType,
  product: Product
): ProductDetails => {
  if (product === Product.Basic || product === Product.Teams) {
    return products[product].monthly;
  }
  return products[product].annual;
};

export const groupPlansByType = (products: ProductDetails[]) => {
  return products.reduce<PlansByType>((acc, plan) => {
    let key: Product;
    if (plan.planType === 'basic') {
      key = Product.Basic;
    } else {
      key = plan.planType as Product;
    }
    if (!acc[key]) {
      acc[key] = {} as PlansByPeriod;
    }
    const period = plan.planId?.includes('Annual') ? 'annual' : 'monthly';
    acc[key][period] = plan;
    return acc;
  }, {} as PlansByType);
};
